import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import VueAxios from 'vue-axios'
import moment from 'moment'
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
import router from './router'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
// import { faLink } from '@fortawesome/pro-duotone-svg-icons'
/* add some pro styles */

import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'

library.add(faEdit)
/* add each imported icon to the library */
library.add(faArrowUpRightFromSquare)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

/* import the fontawesome core */

/* add icons to the library */
// library.add(faLink)

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false
Vue.use(VueAxios, axios)
Vue.prototype.moment = moment
Vue.use(PerfectScrollbar)

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
